<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <!-- CARD 1: CONGRATS -->
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <vx-card slot="no-body" class="text-center bg-primary-gradient greet-user">
                    <img src="@/assets/images/elements/decore-left.png" class="decore-left" alt="Decore Left" width="200" >
                    <img src="@/assets/images/elements/decore-right.png" class="decore-right" alt="Decore Right" width="175">
          <feather-icon icon="ActivityIcon" class="p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow" svgClasses="h-8 w-8"></feather-icon>
          <h1 class="mb-6 text-white">Welcome Back</h1>
          <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">Nice to meet you and have a nice day</p>
        </vx-card>
      </div>

      <!-- CARD 2: SUBSCRIBERS GAINED -->
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <statistics-card-line icon="UsersIcon" statistic="92.6k" statisticTitle="Visitors Gained" :chartData="subscribersGained.series" type="area"></statistics-card-line>
      </div>

      <!-- CARD 3: ORDER RECIEVED -->
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <statistics-card-line icon="ShoppingBagIcon" statistic="90.5K" statisticTitle="Orders Received" :chartData="ordersRecevied.series" color="warning" type="area"></statistics-card-line>
      </div>
    </div>

    <div class="vx-row">
      <h3 class="text-2xl font-semibold mb-5 ml-5">
        Travelers Statistic
      </h3>
      <!-- Guest Progress Graph -->
      <div class="vx-col w-full mb-base">
        <div class="vx-card p-5">
          <div class="vx-row">
            <!-- Top 5 Travelers Countries -->
          <div class="vx-col w-full md:w-4/12">
            <h3 class="text-xl">Top 5 Countries</h3>

            <div class="vx-row mt-5">
              <div class="vx-col w-full mb-2">
                <span class="block text-base font-semibold"
                  >Indonesia ( 45% )</span
                >
                <vs-progress
                  :height="10"
                  :percent="45"
                  :color="getCountriesColor(Number(45))"
                ></vs-progress>
              </div>
              <div class="vx-col w-full">
                <span class="block text-base font-semibold"
                  >Malaysia ( 20% )</span
                >
                <vs-progress
                  :height="10"
                  :percent="25"
                  :color="getCountriesColor(Number(25))"
                ></vs-progress>
              </div>
              <div class="vx-col w-full">
                <span class="block text-base font-semibold"
                  >Singapore ( 15% )</span
                >
                <vs-progress
                  :height="10"
                  :percent="15"
                  :color="getCountriesColor(Number(15))"
                ></vs-progress>
              </div>
              <div class="vx-col w-full">
                <span class="block text-base font-semibold">USA ( 10% )</span>
                <vs-progress
                  :height="10"
                  :percent="10"
                  :color="getCountriesColor(Number(10))"
                ></vs-progress>
              </div>
              <div class="vx-col w-full">
                <span class="block text-base font-semibold">Canada ( 5% )</span>
                <vs-progress
                  :height="10"
                  :percent="5"
                  :color="getCountriesColor(Number(5))"
                ></vs-progress>
              </div>
            </div>
          </div>

          <!-- Domestic and International travelers -->
          <div class="vx-col w-full md:w-4/12">
            <h3 class="text-xl">Domestic and International travelers</h3>

            <div class="vx-row mt-5">
              <div class="vx-col w-full mb-2">
                <span class="block text-base font-semibold"
                  >Domestic ( 75% )</span
                >
                <vs-progress
                  :height="10"
                  :percent="75"
                  color="success"
                ></vs-progress>
              </div>
              <div class="vx-col w-full">
                <span class="block text-base font-semibold"
                  >International ( 25% )</span
                >
                <vs-progress
                  :height="10"
                  :percent="25"
                  color="primary"
                ></vs-progress>
              </div>
            </div>
          </div>

          <!-- Device -->
          <div class="vx-col w-full md:w-4/12">
            <h3 class="text-xl">Device</h3>

            <div class="vx-row mt-5">
              <div class="vx-col w-full mb-2">
                <span class="block text-base font-semibold"
                  >Mobile ( 80% )</span
                >
                <vs-progress
                  :height="10"
                  :percent="80"
                  color="success"
                ></vs-progress>
              </div>
              <div class="vx-col w-full">
                <span class="block text-base font-semibold"
                  >Desktop ( 20% )</span
                >
                <vs-progress
                  :height="10"
                  :percent="20"
                  color="primary"
                ></vs-progress>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>

      <!-- Order lists -->
      <div class="vx-col w-full">
        <div class="vx-card p-5">
          <div class="vx-row">
            <div class="vx-col w-full flex items-center">
              <vs-icon icon-pack="feather" icon="icon-clipboard" />
              <h3 class="text-xl ml-2">Booking Lists</h3>
            </div>
            <div class="vx col w-full p-3">
              <vs-tabs :color="colorx">
                <vs-tab @click="colorx = 'success'" label="Arrivals (7)">
                  <arrival-list-view />
                </vs-tab>

                <vs-tab @click="colorx = '#5594fa'" label="New Bookings (10)">
                  <new-booking-list-view />
                </vs-tab>

                <vs-tab @click="colorx = 'danger'" label="Canceled (3)">
                  <cancel-list-view />
                </vs-tab>
              </vs-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import ArrivalListView from "@/components/dashboard/order-lists/ArrivalListView";
import NewBookingListView from "@/components/dashboard/order-lists/NewBookingListView";
import CancelListView from "@/components/dashboard/order-lists/CancelListView";

export default {
  metaInfo: {
    title: "Dashboard",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  data() {
    return {
      colorx: "success",
      subscribersGained: {},
      ordersRecevied: {},
    };
  },
  components: {
    VueApexCharts,
    StatisticsCardLine,
    ArrivalListView,
    NewBookingListView,
    CancelListView
  },
  methods: {
    getCountriesColor(num) {
      if (num >= 40) return "success";
      if (num >= 25) return "primary";
      if (num < 25) return "warning";
      return "primary";
    }
  },
  mounted () {
    this.$vs.loading({
      type: 'sound'
    })

    setTimeout(() => {
      this.$vs.loading.close()
    }, 2000);
  },
  created() {
    // Subscribers gained - Statistics
    this.$http.get('/api/card/card-statistics/subscribers')
      .then((response) => { this.subscribersGained = response.data })
      .catch((error)   => { console.log(error) })

    // Orders - Statistics
    this.$http.get('/api/card/card-statistics/orders')
      .then((response) => { this.ordersRecevied = response.data })
      .catch((error)   => { console.log(error) })
  }
};
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#dashboard-analytics {
  .greet-user {
    position: relative;

    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }

  th.td-check,
  td.td-check {
    display: none !important;
  }
}
/*! rtl:end:ignore */
</style>
